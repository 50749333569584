
import { CrudCollection } from "../../CrudCollection";

export default {
  data() {
    return {
      loading: false,
      dialog: false,
      success: false,
    };
  },
  props: {
    collection: {
      type: CrudCollection,
      required: true,
    },
  },
  methods: {
    downloadExport() {
      this.loading = true;
      this.collection.model
        .export(this.collection.remoteQueryValues)
        .then((data) => {
          if (this.$nuxt.$config.useQueuedExports) {
            return this.handleQueuedExportResult(data);
          } else {
            return this.handleSyncExportResult(data);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleQueuedExportResult(data) {
      console.log(data);
      this.success = data.success;
      this.dialog = true;
    },
  },
};
