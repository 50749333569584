import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import { BooleanField } from "../plugins/Cruxtify/field-types/BooleanField";
import { RelationshipFieldMany } from "../plugins/Cruxtify/field-types/RelationshipFieldMany";
import { StringField } from "../plugins/Cruxtify/field-types/StringField";
import {
  BooleanProperty,
  FileProperty,
  NumberProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
  StringProperty,
  UrlProperty,
} from "../plugins/Cruxtify/property-types";

import { CrudFilter } from "../plugins/Cruxtify/CrudFilter";
import {
  FileField,
  LongTextField,
  RelationshipField,
} from "../plugins/Cruxtify/field-types";
import { UrlField } from "../plugins/Cruxtify/field-types/UrlField";
import { extendWithDeleted } from "./collection-layouts/extend-with-deleted";
import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";

export class Resource extends CrudModel {
  public static icon = "mdi-folder-multiple-image";
  protected static _typeLabel = "Resource";
  protected static asProperty = "resource";
  public static api = {
    path: "resources",
  };

  protected userIdField = [];
  protected static routeBase = "resources";

  protected labelGlue: string = " ";

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    ...idAndTimestamps.properties,

    {
      type: NumberProperty,
      opts: {
        name: "legacy_id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },

    { type: StringProperty, opts: { name: "name", label: "Name" } },
    { type: FileProperty, opts: { name: "s3_id", label: "File" } },
    { type: StringProperty, opts: { name: "filename", label: "Filename" } },
    {
      type: StringProperty,
      opts: {
        name: "mime_type",
        label: " ",
        UserPermissions: UserPermission.Read,
        sortable: false,
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "is_folder",
        label: "Is a Folder",
        UserPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "legacy_folder_id",
        label: "Is a Folder",
        UserPermissions: UserPermission.Hidden,
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "requires_license",
        label: "Requires Active License",
        description:
          "Limit resource visibility to trainers with active licenses.",
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "requires_admin",
        label: "Only Accessible by Admins",
        description: "Limit resource visibility to admin user accounts.",
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "requires_tradename",
        label: "Requires Active Tradename",
        description:
          "Limit resource visibility to owners of studios with an active tradename.",
      },
    },
    {
      type: StringProperty,
      opts: { name: "description", label: "Description/Note" },
    },
    { type: UrlProperty, opts: { name: "url", label: "URL" } },

    // relationships
    {
      type: RelationshipProperty,
      opts: {
        name: "parent",
        label: "Folder",
        relatedModel: "Resource",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "resource_type",
        label: "Resource Type",
        relatedModel: "ResourceType",
        rules: ["required"],
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "countries",
        label: "Country Visibility (must have AT LEAST 1)",
        relatedModel: "CountryAccessibleResource",
        foreignProperty: "resource",
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "required_courses",
        label: "Required Courses (must have AT LEAST 1)",
        relatedModel: "CourseAccessibleResource",
        foreignProperty: "resource",
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "required_qualifications",
        label: "Required Qualifications (must have AT LEAST 1)",
        relatedModel: "Qualification",
        foreignProperty: "resources",
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "users",
        label: "Users Granted Access",
        description:
          "Users granted access to this resource through purchasing it or directly in the Database.",
        relatedModel: "User",
        foreignProperty: "resources",
      },
    },
  ];

  protected static fieldDefinitions = [
    ...idAndTimestamps.fields,

    {
      type: StringField,
      opts: {
        property: "name",
        isPrimaryLabelField: 1,
        rules: ["required"],
        cols: 12,
      },
    },
    {
      type: FileField,
      opts: {
        property: "s3_id",
        conditional: {
          property: "resource_type",
          value: 1,
        },
      },
    },
    {
      type: StringField,
      opts: {
        property: "filename",
        conditional: {
          property: "resource_type",
          value: 1,
        },
        rules: ["required"],
      },
    },
    {
      type: StringField,
      opts: {
        property: "mime_type",
        viewComponentName: "resource-icon",
      },
    },
    {
      type: BooleanField,
      opts: {
        id: "purchase_icon",
        property: "mime_type",
        viewComponentName: "resource-purchase-icon",
      },
    },
    { type: BooleanField, opts: { property: "requires_license" } },
    { type: BooleanField, opts: { property: "requires_admin" } },
    { type: BooleanField, opts: { property: "requires_tradename" } },
    { type: LongTextField, opts: { property: "description" } },
    {
      type: UrlField,
      opts: {
        property: "url",
        conditional: {
          property: "resource_type",
          value: 2,
        },
      },
    },
    {
      type: StringField,
      opts: {
        id: "vimeo_id",
        label: "Vimeo ID",
        property: "url",
        conditional: {
          property: "resource_type",
          compare: "oneOf",
          value: [3, 4],
        },
      },
    },
    {
      type: RelationshipField,
      opts: {
        id: "viewer",
        label: "",
        property: "resource_type",
        viewComponentName: "resource-viewer",
        formComponentName: "resource-viewer",
      },
    },

    // relationships
    { type: RelationshipField, opts: { property: "parent" } },
    { type: RelationshipField, opts: { property: "resource_type" } },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "countries",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "required_courses",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: { property: "required_qualifications" },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "users",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      type: "TabbedModelLayout",
      opts: {
        id: "admin",
        tabs: [
          {
            id: "overviewTab",
            label: "Overview",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "name",
                    fields: ["id", "name", "parent"],
                  },
                  {
                    id: "resource_info",
                    fields: [
                      "resource_type",
                      "s3_id",
                      "url",
                      "vimeo_id",
                      "filename",
                      "description",
                    ],
                  },
                ],
              },
            },
          },
          {
            id: "accessTab",
            label: "Access Settings",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "access",
                    fields: [
                      "requires_license",
                      "requires_admin",
                      "requires_tradename",
                      "countries",
                      "required_courses",
                      "required_qualifications",
                    ],
                  },
                ],
              },
            },
          },
        ],
      },
    },
    {
      type: "FieldSetGridLayout",
      opts: {
        id: "non-admin",
        fieldSets: [
          {
            id: "name",
            fields: ["name", "description", "viewer"],
          },
        ],
      },
    },
  ];

  protected getDefaultModelLayoutId(): string {
    return this.$nuxt.$auth.user.is_admin ? "admin" : "non-admin";
  }

  protected static collectionLayoutDefinitions = extendWithDeleted([
    {
      id: "table",
      inlineEdit: false,
      enableSearch: true,
      useModal: true,
      noRefresh: true,
      columnFields: ["mime_type", "purchase_icon", "name"],
      filters: [new CrudFilter({ name: "parent", queryValue: "1" })],
      componentName: "resource-data-table",
      defaultSort: [
        {
          field: "name",
          order: "asc",
        },
      ],
    },
    {
      id: "as-relationship",
      inlineEdit: false,
      enableSearch: true,
      useModal: true,
      columnFields: ["mime_type", "name"],
      filters: [new CrudFilter({ name: "parent" })],
      componentName: "resource-data-table",
      defaultSort: [
        {
          field: "name",
          order: "asc",
        },
      ],
    },
    {
      id: "default",
      inlineEdit: false,
      enableSearch: true,
      useModal: false,
      enableDynamicFilters: true,
      collectionActionComponents: ["collection-export"],
      columnFields: ["mime_type", "name", "resource_type", "parent"],
      defaultSort: [
        {
          field: "name",
          order: "asc",
        },
      ],
    },
  ]);

  public static loadBreadcrumb(folderId) {
    return this.$nuxt.$api.GET(`resource-breadcrumb/${folderId}`);
  }
}
